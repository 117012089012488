import React from 'react';
import Chat from './Chat';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #1e1e1e;
    color: #ffffff;
    font-family: 'Fira Code', monospace;
  }
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <Chat />
    </>
  );
}

export default App;
