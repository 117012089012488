// ChatStyles.js
import styled, { keyframes } from 'styled-components';
import ReactMarkdown from 'react-markdown';

// const typingRectangle = keyframes`
//   0% {
//     width: 5px;
//   }
//   50% {
//     width: 15px;
//   }
//   100% {
//     width: 5px;
//   }
// `;

// export const TypingIndicator = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   height: 10px; /* Height of the rectangle */
//   background-color: #61dafb; /* Rectangle color */
//   margin-left: 5px;
//   animation: ${typingRectangle} 0.8s ease-in-out infinite; /* Animation */
// `;

const cropImage = keyframes`
  0% {
    clip-path: inset(0% 0% 0% 100%);
  }
  50% {
    clip-path: inset(0% 0% 0% 0%);
  }
  100% {
    clip-path: inset(0% 100% 0% 0%);
  }
`;

const cropImageStepwise = keyframes`
  0% {
    clip-path: inset(0% 100% 0% 0%); /* Image fully hidden (from left) */
  }
  25% {
    clip-path: inset(0% 66% 0% 0%); /* Reveal one-third of the image */
  }
  50% {
    clip-path: inset(0% 33% 0% 0%); /* Reveal two-thirds of the image */
  }
  75% {
    clip-path: inset(0% 0% 0% 0%); /* Image fully revealed */
  }
`;

const blinkCursor = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const BlinkingCursor = styled.span`
  display: inline-block;
  width: 10px; /* Adjust based on your image size */
  height: 15px; /* Adjust based on your image size */
  margin-left: 5px; /* Add spacing between text and image */
  position: relative; /* Use relative positioning */
  top: 3px; /* Move it down by 5px */
  background: url('/block.png') no-repeat;
  background-size: cover; /* Adjust to fit the image */
  animation: ${blinkCursor} 1s step-start infinite; /* Blinking effect */
`;

export const TypingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TypingText = styled.span`
  font-size: 14px;
  color: #61dafb;
  margin-right: 10px; /* Add spacing between text and image */
`;

export const TypingImage = styled.div`
  width: 25px;
  height: 7px;
  background: url('/dots.png') no-repeat;
  background-size: 100% 100%; /* Ensure the image is scaled to fit */
  animation: ${cropImageStepwise} 1.0s steps(3, end) infinite;
  // filter: invert(1) sepia(1) saturate(500%) hue-rotate(190deg) brightness(0.8);
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 90vh; //calc(var(--vh, 1vh) * 100);
  background-color: #1e1e1e;
  color: #ffffff;
  font-family: 'Fira Code', monospace;
  overflow: hidden; /* Prevents overflow from causing scroll on the whole page */
`;

export const Header = styled.h1`
  margin-top: 20px;
  color: #61dafb;
`;

export const IntroText = styled.p`
  font-size: 1.2rem;
  color: #dcdcdc;
  margin-bottom: 20px;
  text-align: center;
  max-width: 800px;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 800px;
  background-color: #0d0d0d;
  margin: 20px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  flex: 1;
  overflow: hidden;

  
  
  // height: 100%; /* Ensure the chat container takes full height */

  @media (max-width: 600px) {
    margin: 0px; /* Adjust margin for screens smaller than 600px */
    height: 60%;
    //height: calc(10vh - 60px); /* Adjust for mobile view */
  }
`;

// export const MessagesContainer = styled.div`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   // justify-content: flex-end; /* This aligns messages to the bottom */
//   height: 800px;
//   overflow-y: auto;
//   padding-right: 10px;
//   border-bottom: 1px solid #01354b;
//   max-height: 100%;
// `;

export const MessagesContainer = styled.div`
  flex: 1; /* This makes the messages container grow to fill available space */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
  border-bottom: 1px solid #01354b;

  // min-height: 90%; /* Ensure container grows to the full height */
`;

interface MessageProps {
  $isUser: boolean;
}

export const Message = styled.div<MessageProps & { isTyping?: boolean }>`
  background-color: ${(props) => (props.$isUser ? '#013a5b' : '#1e1e1e')};
  color: ${(props) => (props.isTyping ? '#219abb' : '#ffffff')};
  // font-style: ${(props) => (props.isTyping ? 'italic' : 'normal')};
  padding: 6px 10px;
  border-radius: 12px;
  margin-bottom: 10px;
  max-width: 70%;
  width: fit-content;
  align-self: ${(props) => (props.$isUser ? 'flex-end' : 'flex-start')}; /* Controls alignment */
  text-align: ${(props) => (props.$isUser ? 'right' : 'left')}; /* Aligns text within the message */
  margin-left: ${(props) => (props.$isUser ? 'auto' : '0')};
  word-break: break-word;
`;

export const InputContainer = styled.div`
  display: flex;
  padding-top: 10px;
  align-items: center;
  background-color: #0d0d0d; /* Style the input container */
`;

export const Input = styled.textarea`
  flex: 1;  
  height: auto;  
  min-height: 18px;  
  font-size: 16px;  
  padding: 10px;
  border: none;  
  border-radius: 8px;  
  margin-right: 10px;  
  background-color: #3e3e3e;  
  color: #ffffff;  
  resize: none;  
  line-height: 1.5;  
  overflow: hidden;  
`;

export const SendButton = styled.button<{ disabled?: boolean }>`
  padding: 0px 0px;
  width: 42px;
  height: 42px;
  border: none;
  border-radius: 8px;
  background-color: #32a4d1;
  color: #1e1e1e;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.3rem;
  
  &:hover {
    background-color: #41baeb;
  }

  &:disabled {
    background-color: #3e3e3e; /* Dark gray when disabled */
    cursor: not-allowed; /* Show "not allowed" cursor when button is disabled */
  }
`;



// Define styles for markdown content
export const StyledMarkdown = styled(ReactMarkdown)`
  p {
    margin: 0; /* Ensure paragraphs don't add extra margin */
    text-align: inherit; /* Inherit alignment */
  }

  /* Code block styling */
  pre {
    background-color: #2d2d2d; /* Optional: Set a background color for code blocks */
    color: #ffffff; /* Optional: Set a color for the text */
    padding: 8px; /* Add padding inside the code block */
    border-radius: 8px; /* Make the corners of the code block rounded */
    max-width: 100%; /* Ensure the code block doesn't exceed the container width */
    white-space: pre-wrap; /* Wraps the code if it's too long */
    word-wrap: break-word; /* Break long words if needed */
    overflow: hidden; /* Hide overflow if the content exceeds the container */
  }

  /* Inline code styling */
  code {
    background-color: #f5f5f5; /* Optional: Set a background color for inline code */
    color: #c7254e; /* Optional: Set a color for the text */
    padding: 2px 4px; /* Add some padding around inline code */
    border-radius: 4px; /* Make the corners rounded */
    white-space: pre-wrap; /* Ensure inline code wraps when necessary */
    word-wrap: break-word; /* Break long inline code */
  }
`;