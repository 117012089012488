import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import sharedChatService, { localNetwork } from './ChatService';

import {
  PageContainer,
  Header,
  IntroText,
  ChatContainer,
  MessagesContainer,
  Message,
  InputContainer,
  Input,
  SendButton,
  StyledMarkdown,
  BlinkingCursor,
} from './ChatStyles';

interface MessageModel {
  text: string;
  isUser: boolean;
}

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<MessageModel[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [disconnectMessage, setDisconnectMessage] = useState<string>(''); // State for the disconnect message
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const inputEndRef = useRef<HTMLDivElement | null>(null);
  const chatService = useRef(sharedChatService);
  const inputRef = useRef<HTMLTextAreaElement>(null); // Create a ref for the input field
  const [currentUrl, setCurrentUrl] = useState(chatService.current.url);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    // Set up event handlers
    chatService.current.onMessageReceived = (message) => {
      if (message.msg === "TYPING") {
        setIsTyping(true);
      } else {
        setIsTyping(false); // Hide typing indicator
        if (message.msg) {
          setMessages((prevMessages) => {
            // Check if there are already messages and if the message starts with "WELCOME:"
            if (message.msg && message.msg.startsWith("WELCOME:")) {
              if (prevMessages.length > 0) {
                return prevMessages; // Ignore the message if there are existing messages
              } else {
                // Remove the "WELCOME: " prefix from the message
                const trimmedMessage = message.msg.replace("WELCOME: ", "");
                return [
                  ...prevMessages,
                  { text: trimmedMessage, isUser: false },
                ];
              }
            }
    
            // Add the message if it doesn't start with "WELCOME:"
            return [
              ...prevMessages,
              { text: message.msg!, isUser: false },
            ];
          });
        }
      }
    };

    chatService.current.onConnectionStatusChanged = (status) => {
      if (status === 'connected') {
        setDisconnectMessage(''); // Clear the disconnect message on connection
        setIsConnected(true);
      } else if (status === 'disconnected') {
        const reconnectInterval = Math.round(chatService.current.reconnectInterval / 1000);
        setDisconnectMessage(`Disconnected. Retrying in ${reconnectInterval}s`); // Set the disconnect message
        setIsConnected(false);
      }
    };

    // get agent type from the URL
    let params = new URLSearchParams(window.location.search);
    chatService.current.agentType = params.get('agent');
    if (params.get('debug')) {
      chatService.current.url = `ws://${window.location.hostname}:19008/`;
      setCurrentUrl(chatService.current.url);
    }
    chatService.current.connect();

    return () => {
      // Optionally, you can leave the connection open or decide when to close it
    };
  }, []);

  // Monitor newMessage changes and adjust textarea height and scroll into view
  useEffect(() => {
    if (inputRef.current) {
      adjustTextAreaHeight(inputRef.current); // Adjust height based on content
    }

    if (inputEndRef.current) {
      inputEndRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll into view
    }
  }, [newMessage]);
  

  useEffect(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        adjustTextAreaHeight(inputRef.current);
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        inputEndRef.current?.scrollIntoView({ behavior: 'smooth' }); // Scroll into view
      }, 0);
    }
  }, [messages, isTyping]);

  // Set focus to the input field when the component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const sent = chatService.current.send(newMessage)
      const all_messages = [...messages, { text: newMessage, isUser: true }]
      if (!sent) {
        all_messages.push({ text: "Could not send the message, please try again.", isUser: false });
      }
      setMessages(all_messages);
      setNewMessage('');
      // keep focus on the input field
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const adjustTextAreaHeight = (el: HTMLTextAreaElement | null) => {
    if (!el) return;
    el.style.height = 'auto'; // Reset the height
    el.style.height = `${Math.min(el.scrollHeight - 20, 240)}px`; // Set the height dynamically up to 240px (~10 rows)
  };

  return (
    <PageContainer>
      <Header>Remedee for Django{chatService.current.agentType ? ` (${chatService.current.agentType})` : ''}</Header>
      <IntroText>
        {disconnectMessage && <small style={{ display: 'block', marginTop: '10px', fontSize: '12px', color: 'lightcoral' }}>{disconnectMessage}</small>}
      </IntroText>
      <ChatContainer>
        <MessagesContainer>
          <div style={{ flexGrow: 1 }}></div> {/* Empty div to push content to the bottom */}
          {messages.map((message, index) => (
            <Message key={index} $isUser={message.isUser}>
              <StyledMarkdown>
                {typeof message.text === 'string' ? message.text.replace(/\n/g, '  \n') : ''}
              </StyledMarkdown>
            </Message>
          ))}
          {isTyping && (
            <Message $isUser={false} isTyping={true}>
                🧐<BlinkingCursor />
            </Message>
          )}
          <div ref={messagesEndRef} />
        </MessagesContainer>
        <InputContainer>
          <Input
            as="textarea"
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => {
              setNewMessage(e.target.value);
              adjustTextAreaHeight(inputRef.current); // Adjust height dynamically
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.metaKey) {
                e.preventDefault();
                handleSendMessage();
              } else if (e.key === 'Enter' && !e.shiftKey) {
                //setNewMessage(newMessage); // Add a new line
              }
            }}
            rows={1} // Start with 1 row
            ref={inputRef}
            style={{ maxHeight: '240px', overflowY: 'auto' }} // Limit to 10 lines (roughly 240px)
          />
          <SendButton onClick={handleSendMessage} disabled={!isConnected}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </SendButton>
          <div ref={inputEndRef} />
        </InputContainer>
      </ChatContainer>
      {<small style={{ 
          display: 'block', 
          margin: '0 0px',
          padding: '3px 5px',
          fontSize: '12px', 
          color: 'gray', 
          textAlign: 'center' // Centers the text horizontally
        }}>By messaging Remedee, you agree to our <a 
      style={{ color: '#8D98E6' }} 
      href="https://remedeeai.notion.site/Terms-of-Service-d86d0b794524463fad996f416b22c9a1">Terms</a> and have read our <a 
      style={{ color: '#8D98E6' }}
      href="https://remedeeai.notion.site/Privacy-Policy-7c43e832cb194a8596116b1d593c2db4">Privacy Policy</a>.</small>}
      {localNetwork && <small style={{ display: 'block', marginTop: '10px', fontSize: '12px', color: 'gray' }}>{currentUrl}</small>}
    </PageContainer>
  );
}

export default Chat;
